import PropTypes from 'prop-types';
import Card from 'components/Card';
import IconHeading from 'components/IconHeading';
import { useLazyQuery } from '@apollo/client';
import { EXTRA_VACANCIES_DATA } from 'lib/queries';
import { useEffect, useMemo, useRef, useState } from 'react';
import { JobPostingType } from 'lib/proptypes/jobPosting';
import { createJobPostingGhosts } from 'utils/createJobPostingGhosts';
import JobPosting from '@molecules/JobPosting/JobPosting';
import JobPostingPreview from '@molecules/JobPostingPreview/JobPostingPreview';
import { VIEW_MODES } from 'lib/constants';

const JobPostingList = ({
  Icon,
  heading,
  HeadingTag,
  jobPostings,
  skipFetchExtraData,
  cta,
  extraClasses,
  ghosts,
  test,
  compact = false,
  loading = false,
  splitView = false,
}) => {
  const [extraVacanciesDataQuery] = useLazyQuery(EXTRA_VACANCIES_DATA);
  const [extraData, setExtraData] = useState();
  const [activeJobPosting, setActiveJobPosting] = useState(
    jobPostings[0]?.node.id || ''
  );

  const ref = useRef(null);

  const ghostArray = useMemo(() => createJobPostingGhosts(ghosts), [ghosts]);

  useEffect(() => {
    if (!compact && !skipFetchExtraData) {
      const vacanciesIds = jobPostings.map(({ node: { id } }) => id);
      extraVacanciesDataQuery({
        variables: {
          ids: vacanciesIds,
        },
      }).then(({ data }) => {
        const mappedData = data?.vacancies?.edges?.map(
          ({
            node: {
              id,
              slug,
              vacancyStats: { views },
            },
          }) => ({
            id,
            slug,
            views,
          })
        );
        return setExtraData(mappedData);
      });
    }
  }, [compact, skipFetchExtraData, jobPostings]);

  const handleJobPostingClick = (e, id) => {
    const screenWidth = window.innerWidth;

    if (splitView !== VIEW_MODES.SPLIT || screenWidth < 1280) return;

    e.preventDefault();
    setActiveJobPosting(id);

    ref.current?.scrollIntoView();
  };

  return (
    <div
      className={`c-job-posting-list  ${
        loading ? 'c-job-posting-list--loading' : ''
      } ${extraClasses || ''}`
        .replace(/\s+/g, ' ')
        .trim()}
    >
      {!!Icon && !!heading && (
        <IconHeading Icon={Icon} heading={heading} HeadingTag={HeadingTag} />
      )}
      <div
        className={
          splitView === VIEW_MODES.SPLIT
            ? 'c-job-posting-list__split-container'
            : ''
        }
      >
        <ul className="o-list-clean c-job-posting-list__list" data-cy={test}>
          {/* placeholder cards */}
          {!!loading &&
            ghostArray.map(({ title, company, location, hours }, i) => (
              <li
                key={`ghost-${i}`}
                className="u-margin-bottom-small--not-last-child"
                aria-hidden
              >
                <Card
                  extraClasses="c-job-posting-list__ghost"
                  title={title}
                  location={location}
                  company={company}
                  hours={hours}
                />
              </li>
            ))}
          {/* real cards */}
          {!!jobPostings &&
            jobPostings.map(jobPosting => (
              <li
                key={jobPosting.node.id}
                className="u-margin-bottom-small--not-last-child"
              >
                <JobPosting
                  isActive={activeJobPosting === jobPosting.node.id}
                  jobPosting={jobPosting}
                  extraData={extraData}
                  compact={compact}
                  cta={cta}
                  onClick={e => handleJobPostingClick(e, jobPosting.node.id)}
                />
              </li>
            ))}
        </ul>

        {splitView === VIEW_MODES.SPLIT && (
          <div ref={ref} className="c-job-posting-list__preview">
            <JobPostingPreview id={activeJobPosting} />
          </div>
        )}
      </div>
    </div>
  );
};

JobPostingList.propTypes = {
  Icon: PropTypes.func,
  heading: PropTypes.string,
  HeadingTag: PropTypes.string,
  jobPostings: PropTypes.arrayOf(JobPostingType),
  compact: PropTypes.bool,
  cta: PropTypes.string,
  extraClasses: PropTypes.string,
  loading: PropTypes.bool,
  splitView: PropTypes.oneOf(Object.values(VIEW_MODES)),
  ghosts: PropTypes.number,
};

export default JobPostingList;
